// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import Callout, {CalloutProps} from '@shared/design/components/Callout';

const WarningCallout = (props: CalloutProps) => {
  return (
    <Callout
      textColor={colors.orange.status}
      backgroundColor={colors.orange.accent}
      borderColor={colors.orange.status}
      icon={Icon.ExclamationTriangle}
      iconColor={colors.orange.status}
      {...props}
    />
  );
};

export default WarningCallout;
