// Supermove
import {gql} from '@supermove/graphql';
import {Call, Email, CommunicationModel, TextMessage, EmailStatus} from '@supermove/models';
import {getStatus} from '@supermove/models/src/Conversation';
import {withFragment, Datetime} from '@supermove/utils';

import {CallStatusType} from '@shared/modules/Call/enums/CallStatus';

const getDisplayDate = withFragment(
  (conversation: CommunicationModel) => {
    let displayDate: string | null = null;
    if (conversation.textMessage) {
      displayDate = TextMessage.getDisplayDate(conversation.textMessage);
    }
    if (conversation.email) {
      displayDate = Email.getDisplayDate(conversation.email);
    }
    if (conversation.call) {
      displayDate = Call.getDisplayDate(conversation.call);
    }

    if (!displayDate) {
      displayDate = Datetime.toDisplayDate(
        Datetime.fromDatetime(conversation.createdAt),
        'M/DD/YYYY',
      );
    }
    return displayDate;
  },
  gql`
    ${TextMessage.getDisplayDate.fragment}
    ${Email.getDisplayDate.fragment}
    ${Call.getDisplayDate.fragment}
    fragment Communication_getDisplayDate on Communication {
      id
      createdAt
      textMessage {
        id
        ...TextMessage_getDisplayDate
      }
      email {
        id
        ...Email_getDisplayDate
      }
      call {
        id
        ...Call_getDisplayDate
      }
    }
  `,
);

const getStatusInfo = withFragment(
  (communication: CommunicationModel) => {
    const type = (() => {
      if (communication.email) {
        return 'EMAIL';
      }
      if (communication.textMessage) {
        return 'TEXT';
      }
      if (communication.call) {
        return 'CALL';
      }
    })();
    return getStatus({
      direction: communication.direction,
      emailStatus: communication.email?.status as EmailStatus,
      callStatus: communication.call?.status as CallStatusType,
      type,
    });
  },
  gql`
    fragment Communication_getStatusInfo on Communication {
      id
      direction
      textMessage {
        id
      }
      email {
        id
        status
      }
      call {
        id
        status
      }
    }
  `,
);

const Communication = {
  getDisplayDate,
  getStatusInfo,
};

export default Communication;
