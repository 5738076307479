// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {BillRuleModel} from '@supermove/models';
import {Currency, withFragment} from '@supermove/utils';

const KIND_ADDITIONAL_HOURS = 'ADDITIONAL_HOURS';
const KIND_BEFORE_JOB_MINIMUM_PAYMENT = 'BEFORE_JOB_MINIMUM_PAYMENT';
const KIND_CUSTOM = 'CUSTOM';
const KIND_MINIMUM_DOLLAR_AMOUNT = 'MINIMUM_DOLLAR_AMOUNT';
const KIND_MINIMUM_HOURS = 'MINIMUM_HOURS';
const KIND_WAIVE_OVERTIME = 'WAIVE_OVERTIME';
const KIND_NOT_TO_EXCEED_PRICE = 'NOT_TO_EXCEED_PRICE';
const KIND_CONDITIONAL_BILL_ITEM = 'CONDITIONAL_BILL_ITEM';

const KIND_DISPLAY_ADDITIONAL_HOURS = '# Additional Hours';
const KIND_DISPLAY_BEFORE_JOB_MINIMUM_PAYMENT = 'Minimum Payment Before the Move';
const KIND_DISPLAY_CUSTOM = 'Custom Rule';
const KIND_DISPLAY_MINIMUM_DOLLAR_AMOUNT = '$ Amount Minimum';
const KIND_DISPLAY_MINIMUM_HOURS = '# Hours Minimum';
const KIND_DISPLAY_WAIVE_OVERTIME = 'Waive Overtime';
const KIND_DISPLAY_NOT_TO_EXCEED_PRICE = 'Not to Exceed Price';
const KIND_DISPLAY_CONDITIONAL_BILL_ITEM = 'Conditional Bill Item';

const VALUE_TYPE_CURRENCY = 'CURRENCY';
const VALUE_TYPE_HOURS = 'HOURS';
const VALUE_TYPE_CUSTOM = 'CUSTOM';

const getDefaultNameByKind = (kind: any) => {
  switch (kind) {
    case KIND_ADDITIONAL_HOURS:
      return 'Additional Hours';
    case KIND_BEFORE_JOB_MINIMUM_PAYMENT:
      return 'Minimum payment before job starts';
    case KIND_MINIMUM_DOLLAR_AMOUNT:
      return 'Minimum amount';
    case KIND_MINIMUM_HOURS:
      return 'Minimum number of hours';
    case KIND_WAIVE_OVERTIME:
      return 'Waive overtime';
    case KIND_NOT_TO_EXCEED_PRICE:
      return 'Not to exceed price';
    case KIND_CONDITIONAL_BILL_ITEM:
    case KIND_CUSTOM:
    default:
      return '';
  }
};

const getDisplayKind = withFragment(
  (billRule) => {
    switch ((billRule as any).kind) {
      case KIND_ADDITIONAL_HOURS:
        return KIND_DISPLAY_ADDITIONAL_HOURS;
      case KIND_BEFORE_JOB_MINIMUM_PAYMENT:
        return KIND_DISPLAY_BEFORE_JOB_MINIMUM_PAYMENT;
      case KIND_MINIMUM_DOLLAR_AMOUNT:
        return KIND_DISPLAY_MINIMUM_DOLLAR_AMOUNT;
      case KIND_MINIMUM_HOURS:
        return KIND_DISPLAY_MINIMUM_HOURS;
      case KIND_WAIVE_OVERTIME:
        return KIND_DISPLAY_WAIVE_OVERTIME;
      case KIND_NOT_TO_EXCEED_PRICE:
        return KIND_DISPLAY_NOT_TO_EXCEED_PRICE;
      case KIND_CONDITIONAL_BILL_ITEM:
        return KIND_DISPLAY_CONDITIONAL_BILL_ITEM;
      case KIND_CUSTOM:
      default:
        return KIND_DISPLAY_CUSTOM;
    }
  },
  gql`
    fragment BillRule_getDisplayKind on BillRule {
      kind
    }
  `,
);

const getDisplayValues = withFragment(
  (billRule: BillRuleModel) => {
    const {values} = billRule;
    if (!Array.isArray(values)) {
      return '';
    }
    const valueOne = _.get(values, '0', null);
    switch (billRule.kind) {
      case KIND_BEFORE_JOB_MINIMUM_PAYMENT:
      case KIND_MINIMUM_DOLLAR_AMOUNT:
      case KIND_NOT_TO_EXCEED_PRICE:
        return valueOne ? Currency.display(valueOne, {shouldHideCentsIfZero: true}) : '';
      case KIND_ADDITIONAL_HOURS:
      case KIND_MINIMUM_HOURS:
        return valueOne ? `${valueOne} hours` : '';
      case KIND_CUSTOM:
        return valueOne || '';
      case KIND_CONDITIONAL_BILL_ITEM:
      default:
        return '';
    }
  },
  gql`
    fragment BillRule_getDisplayValues on BillRule {
      kind
      values
    }
  `,
);

const getIsEditableName = withFragment(
  (billRule) => {
    if ((billRule as any).nameFormulaId) {
      return false;
    }
    return true;
  },
  gql`
    fragment BillRule_getIsEditableName on BillRule {
      kind
      nameFormulaId
    }
  `,
);

const getIsEditableDescription = withFragment(
  (billRule) => {
    if ((billRule as any).descriptionFormulaId) {
      return false;
    }
    return true;
  },
  gql`
    fragment BillRule_getIsEditableDescription on BillRule {
      kind
      descriptionFormulaId
    }
  `,
);

const getIsEditableValue = withFragment(
  (billRule) => {
    if ((billRule as any).valueFormulaId) {
      return false;
    }
    switch ((billRule as any).kind) {
      case KIND_WAIVE_OVERTIME:
        return false;
      default:
        return true;
    }
  },
  gql`
    fragment BillRule_getIsEditableValue on BillRule {
      kind
      valueFormulaId
    }
  `,
);

const BillRule = {
  KINDS: {
    ADDITIONAL_HOURS: KIND_ADDITIONAL_HOURS,
    BEFORE_JOB_MINIMUM_PAYMENT: KIND_BEFORE_JOB_MINIMUM_PAYMENT,
    CUSTOM: KIND_CUSTOM,
    MINIMUM_DOLLAR_AMOUNT: KIND_MINIMUM_DOLLAR_AMOUNT,
    MINIMUM_HOURS: KIND_MINIMUM_HOURS,
    NOT_TO_EXCEED_PRICE: KIND_NOT_TO_EXCEED_PRICE,
    WAIVE_OVERTIME: KIND_WAIVE_OVERTIME,
    CONDITIONAL_BILL_ITEM: KIND_CONDITIONAL_BILL_ITEM,
  },

  KINDS_DROPDOWN_OPTIONS_V1: [
    {label: KIND_DISPLAY_BEFORE_JOB_MINIMUM_PAYMENT, value: KIND_BEFORE_JOB_MINIMUM_PAYMENT},
    {label: KIND_DISPLAY_MINIMUM_DOLLAR_AMOUNT, value: KIND_MINIMUM_DOLLAR_AMOUNT},
    {label: KIND_DISPLAY_NOT_TO_EXCEED_PRICE, value: KIND_NOT_TO_EXCEED_PRICE},
    {label: KIND_DISPLAY_ADDITIONAL_HOURS, value: KIND_ADDITIONAL_HOURS},
    {label: KIND_DISPLAY_MINIMUM_HOURS, value: KIND_MINIMUM_HOURS},
    {label: KIND_DISPLAY_WAIVE_OVERTIME, value: KIND_WAIVE_OVERTIME},
    {label: KIND_DISPLAY_CUSTOM, value: KIND_CUSTOM},
  ],

  KINDS_DROPDOWN_OPTIONS: [
    {
      label: KIND_DISPLAY_MINIMUM_DOLLAR_AMOUNT,
      value: KIND_MINIMUM_DOLLAR_AMOUNT,
      description: 'Display a minimum amount to charge on the bill.',
    },
    {
      label: KIND_DISPLAY_MINIMUM_HOURS,
      value: KIND_MINIMUM_HOURS,
      description: 'Display the minimum billable hours.',
    },
    {
      label: KIND_DISPLAY_ADDITIONAL_HOURS,
      value: KIND_ADDITIONAL_HOURS,
      description: 'Specify the number of extra crew hours to include in payroll.',
    },
    {
      label: KIND_DISPLAY_CONDITIONAL_BILL_ITEM,
      value: KIND_CONDITIONAL_BILL_ITEM,
      description: 'Add a bill item to the bill when it has a specific value.',
    },
    {
      label: KIND_DISPLAY_BEFORE_JOB_MINIMUM_PAYMENT,
      value: KIND_BEFORE_JOB_MINIMUM_PAYMENT,
      description: 'Display an amount to collect before the move begins.',
    },
    {
      label: KIND_DISPLAY_NOT_TO_EXCEED_PRICE,
      value: KIND_NOT_TO_EXCEED_PRICE,
      description: 'Display a maximum bill total.',
    },
    {
      label: KIND_DISPLAY_WAIVE_OVERTIME,
      value: KIND_WAIVE_OVERTIME,
      description: 'Waive additional overtime charges.',
    },
    {label: KIND_DISPLAY_CUSTOM, value: KIND_CUSTOM, description: 'Create a custom billing rule.'},
  ],

  VALUE_TYPES: {
    CURRENCY: VALUE_TYPE_CURRENCY,
    HOURS: VALUE_TYPE_HOURS,
    CUSTOM: VALUE_TYPE_CUSTOM,
  },

  getDefaultNameByKind,
  getDisplayKind,
  getDisplayValues,
  getIsEditableValue,
  getIsEditableName,
  getIsEditableDescription,
};

export default BillRule;
