// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';

// App
import Tabs, {TabsProps, TabComponentProps, TabType} from '@shared/design/components/Tabs';

const FullWidthTabsRow = Styled.View`
  flex-direction: row;
`;

const FullWidthTab = <T,>({
  tab,
  handlePressTab,
  isActive,
  style = {},
}: TabComponentProps<T & TabType>) => {
  return (
    <Tabs.TabButton onPress={handlePressTab} isActive={isActive} style={{flex: 1, ...style}}>
      <Tabs.TabContent tab={tab} isActive={isActive} />
    </Tabs.TabButton>
  );
};

const FullWidthTabs = <T,>({
  tabs,
  handlePressTab,
  TabComponent = FullWidthTab,
  activeTabIndex = 0,
  style,
}: TabsProps<T>) => {
  return (
    <FullWidthTabsRow style={style}>
      <Tabs.TabsItems
        tabs={tabs}
        handlePressTab={handlePressTab}
        TabComponent={TabComponent}
        activeTabIndex={activeTabIndex}
        isFullWidth
      />
    </FullWidthTabsRow>
  );
};

export default FullWidthTabs;
