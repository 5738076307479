// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import ChangePrimaryPhoneNumberForm, {
  ChangePrimaryPhoneNumberFormType,
} from '@shared/modules/Organization/forms/ChangePrimaryPhoneNumberForm';

export type ChangePrimaryPhoneNumberFormWrappedType = {
  changePrimaryPhoneNumberForm: ChangePrimaryPhoneNumberFormType;
};

const useChangePrimaryPhoneNumberMutation = ({
  changePrimaryPhoneNumberForm,
  onSuccess,
  onError,
}: {
  changePrimaryPhoneNumberForm: ChangePrimaryPhoneNumberFormType;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}) => {
  const form = useForm({
    enableReinitialize: true,
    initialValues: {
      changePrimaryPhoneNumberForm: ChangePrimaryPhoneNumberForm.toForm(
        changePrimaryPhoneNumberForm,
      ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useChangePrimaryPhoneNumberMutation.mutation,
    variables: {
      changePrimaryPhoneNumberForm: ChangePrimaryPhoneNumberForm.toMutation(
        form.values.changePrimaryPhoneNumberForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useChangePrimaryPhoneNumberMutation.mutation = gql`
  mutation useChangePrimaryPhoneNumberMutation(
    $changePrimaryPhoneNumberForm: ChangePrimaryPhoneNumberForm!
  ) {
    response: changePrimaryPhoneNumber(
      changePrimaryPhoneNumberForm: $changePrimaryPhoneNumberForm
    ) {
      ${gql.errors}
      phoneNumber {
        id
        number
      }
    }
  }
`;

export default useChangePrimaryPhoneNumberMutation;
