// Supermove
import {gql} from '@supermove/graphql';
import {SalesAppSettingsModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export interface SalesAppSettingsFormType {
  salesAppSettingsId: string;
  updatedById?: string;
  dialExternalProviderForOutboundCalls: boolean;
}

const edit = withFragment(
  (
    salesAppSettings: SalesAppSettingsModel,
    {updatedById}: {updatedById?: string} = {},
  ): SalesAppSettingsFormType => ({
    salesAppSettingsId: salesAppSettings.id,
    updatedById,
    dialExternalProviderForOutboundCalls: salesAppSettings.dialExternalProviderForOutboundCalls,
  }),
  gql`
    fragment SalesAppSettingsForm_edit on SalesAppSettings {
      id
      dialExternalProviderForOutboundCalls
    }
  `,
);

const toForm = ({
  salesAppSettingsId,
  updatedById,
  dialExternalProviderForOutboundCalls,
}: SalesAppSettingsFormType) => ({
  salesAppSettingsId,
  updatedById,
  dialExternalProviderForOutboundCalls,
});

export type SalesAppSettingsFormToFormType = ReturnType<typeof toForm>;

const toMutation = ({
  salesAppSettingsId,
  updatedById,
  dialExternalProviderForOutboundCalls,
}: SalesAppSettingsFormToFormType) => ({
  salesAppSettingsId,
  updatedById,
  dialExternalProviderForOutboundCalls,
});

const SalesAppSettingsForm = {
  edit,
  toForm,
  toMutation,
};

export default SalesAppSettingsForm;
