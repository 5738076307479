// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {Environment} from '@supermove/sdk';
import {colors} from '@supermove/styles';

// App
import Badge from '@shared/design/components/Badge';

const EnvironmentBadge = () => {
  const isVisible = !Environment.isProduction();
  if (!isVisible) {
    return null;
  }
  return (
    <React.Fragment>
      <Space width={16} />
      <Badge
        iconLeft={Icon.Code}
        label={Environment.getEnvironmentName()}
        color={colors.red.warning}
      />
    </React.Fragment>
  );
};

export default EnvironmentBadge;
