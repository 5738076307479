// Libraries

// Supermove
import {gql} from '@supermove/graphql';
import {withFragment} from '@supermove/utils';

export interface UpdatePrimaryPhoneForwardingPhoneNumberFormProps {
  organizationId: string;
  forwardingPhoneNumber?: string;
}

const edit = withFragment(
  (settings: UpdatePrimaryPhoneForwardingPhoneNumberFormProps) => ({
    organizationId: settings.organizationId,
    forwardingPhoneNumber: settings.forwardingPhoneNumber,
  }),
  gql`
    fragment UpdatePrimaryPhoneForwardingPhoneNumberForm_edit on Settings {
      id
      organizationId
      forwardingPhoneNumber
    }
  `,
);

const toForm = ({organizationId, forwardingPhoneNumber}: any) => ({
  organizationId,
  forwardingPhoneNumber,
});

const toMutation = ({organizationId, forwardingPhoneNumber}: any) => ({
  organizationId,
  forwardingPhoneNumber,
});

const UpdatePrimaryPhoneForwardingPhoneNumberForm = {
  edit,
  toForm,
  toMutation,
};

export default UpdatePrimaryPhoneForwardingPhoneNumberForm;
