// Libraries
import _ from 'lodash';

// Supermove
import {Validation} from '@supermove/utils';
import {ValidationErrors} from '@supermove/utils/src/Validation';

// App
import CompanyKind, {CompanyKindType} from '@shared/modules/Company/enums/CompanyKind';
import CreateCompanyStepKinds from '@shared/modules/Company/enums/CreateCompanyStepKinds';
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';
import SupermoveProductForm, {
  SupermoveProductFormProps,
} from '@shared/modules/SupermoveProduct/forms/SupermoveProductForm';

export interface CompanyFormProps {
  companyId: number | null;
  name: string;
  identifier: string;
  supermoveProductForms: SupermoveProductFormProps[];
  sourceOrganizationId?: number;
  kind: CompanyKindType;
  salesforceId: string;

  // Private
  sourceOrganizationName?: string;
}

const _new = ({
  supermoveProductForms,
}: {
  supermoveProductForms?: SupermoveProductFormProps[];
}): CompanyFormProps => ({
  companyId: null,
  name: '',
  identifier: '',
  supermoveProductForms: supermoveProductForms || [],
  sourceOrganizationId: undefined,
  kind: CompanyKind.CUSTOMER,
  salesforceId: '',

  // Private
  sourceOrganizationName: '',
});

const toForm = (companyForm: CompanyFormProps): CompanyFormProps => ({
  companyId: companyForm.companyId,
  name: companyForm.name,
  identifier: companyForm.identifier,
  supermoveProductForms: companyForm.supermoveProductForms.map((supermoveProductForm) =>
    SupermoveProductForm.toForm(supermoveProductForm),
  ),
  sourceOrganizationId: companyForm.sourceOrganizationId,
  kind: companyForm.kind,
  salesforceId: companyForm.salesforceId,

  // Private
  sourceOrganizationName: companyForm.sourceOrganizationName,
});

const toMutation = (companyForm: CompanyFormProps) => ({
  companyId: companyForm.companyId,
  name: companyForm.name,
  identifier: companyForm.identifier,
  supermoveProductForms: companyForm.supermoveProductForms
    .filter((supermoveProductForm) => supermoveProductForm.status === SupermoveProductStatus.ACTIVE)
    .map((supermoveProductForm) => SupermoveProductForm.toMutation(supermoveProductForm)),
  sourceOrganizationId: companyForm.sourceOrganizationId
    ? _.toNumber(companyForm.sourceOrganizationId)
    : undefined,
  kind: companyForm.kind,
  salesforceId: companyForm.salesforceId,
});

// Valid identifiers must be at least 3 characters long and only contain letters, numbers, -, and _
const validateIdentifier = ({form, field}: {form: any; field: string}) => {
  const identifier = _.get(form.values, field);
  const isValidIdentifier =
    identifier.length >= 3 && /^[a-zA-Z0-9-_]+$/.test(identifier) && !identifier.includes(' ');
  if (!isValidIdentifier) {
    return {
      [field]: `This identifier does not meet the requirements\n • Must be unique\n • Must contain at least 3 characters\n • Only letters, numbers, -, and _ are allowed\n • Cannot contain spaces`,
    };
  }
  return {};
};

const validateSalesforceId = ({
  form,
  field,
  existingSalesforceIds = [],
}: {
  form: any;
  field: string;
  existingSalesforceIds: string[];
}) => {
  if (_.get(form.values, 'companyForm.kind') !== CompanyKind.CUSTOMER) {
    return {};
  }
  const errors = Validation.requiredStringField({
    form,
    field,
    message: 'Please enter a Salesforce ID.',
  });
  if (!_.isEmpty(errors)) {
    return errors;
  }
  const salesforceId = _.get(form.values, field);
  if (_.includes(existingSalesforceIds, salesforceId)) {
    return {
      [field]: 'Please enter a unique Salesforce ID.',
    };
  }
  return {};
};

const validateCompanyDetails = ({
  form,
  existingSalesforceIds = [],
}: {
  form: any;
  existingSalesforceIds: string[];
}) => {
  const nameErrors = Validation.requiredStringField({
    form,
    field: 'companyForm.name',
    message: 'Please enter a company name.',
  });
  const identifierErrors = validateIdentifier({form, field: 'companyForm.identifier'});
  const salesforceIdErrors = validateSalesforceId({
    form,
    field: 'companyForm.salesforceId',
    existingSalesforceIds,
  });
  return {
    ...nameErrors,
    ...identifierErrors,
    ...salesforceIdErrors,
  };
};

const validateProductsOverview = ({form}: {form: any}) => {
  const supermoveProductForms = _.get(form.values, 'companyForm.supermoveProductForms');
  const incompleteActiveProductForms = supermoveProductForms.filter(
    (supermoveProductForm: SupermoveProductFormProps) =>
      supermoveProductForm.status === SupermoveProductStatus.ACTIVE &&
      !supermoveProductForm.isSetupComplete,
  );
  return {
    ...(incompleteActiveProductForms.length
      ? {
          'companyForm.supermoveProductForms':
            'Please set up the products before moving onto the next step.',
        }
      : {}),
  };
};

const validateOfficeApp = ({form}: {form: any}) => {
  const supermoveProductForms = _.get(form.values, 'companyForm.supermoveProductForms');
  const index = _.findIndex(
    supermoveProductForms,
    (supermoveProductForm: SupermoveProductFormProps) =>
      supermoveProductForm.kind === SupermoveProductKind.OFFICE,
  );
  const contractedTruckQuantityErrors = Validation.requiredStringField({
    form,
    field: `companyForm.supermoveProductForms.${index}.contractedTruckQuantity`,
    message: 'Please enter a number.',
  });
  return {
    ...contractedTruckQuantityErrors,
  };
};

const validateSalesApp = ({form}: {form: any}) => {
  const supermoveProductForms = _.get(form.values, 'companyForm.supermoveProductForms');
  const index = _.findIndex(
    supermoveProductForms,
    (supermoveProductForm: SupermoveProductFormProps) =>
      supermoveProductForm.kind === SupermoveProductKind.SALES,
  );
  const numberOfLicensesErrors = Validation.requiredStringField({
    form,
    field: `companyForm.supermoveProductForms.${index}.numberOfLicenses`,
    message: 'Please enter a number.',
  });
  return {
    ...numberOfLicensesErrors,
  };
};

const validateFormForStep = ({
  stepKind,
  form,
  existingSalesforceIds = [],
}: {
  stepKind: string;
  form: any;
  existingSalesforceIds?: string[];
}): ValidationErrors => {
  switch (stepKind) {
    case CreateCompanyStepKinds.COMPANY_DETAILS:
      return validateCompanyDetails({form, existingSalesforceIds});
    case CreateCompanyStepKinds.PRODUCTS.OVERVIEW:
      return validateProductsOverview({form});
    case CreateCompanyStepKinds.PRODUCTS.OFFICE:
      return validateOfficeApp({form});
    case CreateCompanyStepKinds.PRODUCTS.SALES:
      return validateSalesApp({form});
    default:
      return {};
  }
};

const validateStep = async ({
  stepKind,
  form,
  existingSalesforceIds = [],
}: {
  stepKind: string;
  form: any;
  existingSalesforceIds?: string[];
}): Promise<ValidationErrors> => {
  const errors = validateFormForStep({stepKind, form, existingSalesforceIds});
  await form.setTouched(_.mapValues(errors, () => true));
  await form.setErrors(errors);
  return errors;
};

const CompanyForm = {
  new: _new,
  toForm,
  toMutation,

  // Helpers
  validateStep,
};

export default CompanyForm;
