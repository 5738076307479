export interface ChangePrimaryPhoneNumberFormType {
  primaryPhoneNumberId: string;
  organizationId: string;
  phoneNumber: string;
}

const _new = ({
  primaryPhoneNumberId,
  organizationId,
}: {
  primaryPhoneNumberId: string;
  organizationId: string;
}): ChangePrimaryPhoneNumberFormType => {
  return {
    primaryPhoneNumberId,
    organizationId,
    phoneNumber: '',
  };
};

const toForm = (changePrimaryPhoneNumberForm: ChangePrimaryPhoneNumberFormType) => ({
  primaryPhoneNumberId: changePrimaryPhoneNumberForm.primaryPhoneNumberId,
  organizationId: changePrimaryPhoneNumberForm.organizationId,
  phoneNumber: changePrimaryPhoneNumberForm.phoneNumber,
});

const toMutation = (changePrimaryPhoneNumberForm: ChangePrimaryPhoneNumberFormType) => ({
  primaryPhoneNumberId: changePrimaryPhoneNumberForm.primaryPhoneNumberId,
  organizationId: changePrimaryPhoneNumberForm.organizationId,
  phoneNumber: changePrimaryPhoneNumberForm.phoneNumber,
});

const AssignSalespersonToCallForm = {
  toForm,
  toMutation,
  new: _new,
};

export default AssignSalespersonToCallForm;
