// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DragAndDropList} from '@supermove/components';
import {useState} from '@supermove/hooks';
import {List} from '@supermove/utils';

// App
import EditableSortableListContext from '@shared/modules/App/components/EditableSortableList/EditableSortableListContext';
import EditableSortableListItem from '@shared/modules/App/components/EditableSortableList/EditableSortableListItem';

const EditableSortableList = ({
  dragAndDropOptions,
  isDisabled,
  handleReorder,
  children,
}: {
  dragAndDropOptions: {
    isReordering: boolean;
    handleReorderStart: () => void;
    handleReorderEnd: () => void;
  };
  isDisabled?: boolean;
  handleReorder: ({fromIndex, toIndex}: {fromIndex: number; toIndex: number}) => void;
  children: React.ReactElement[];
}) => {
  const [indexOfEdit, setIndexOfEdit] = useState<number | null>(null);

  return (
    <EditableSortableListContext.Provider value={{indexOfEdit, setIndexOfEdit}}>
      <DragAndDropList
        isDisabled={isDisabled}
        isReordering={dragAndDropOptions.isReordering}
        onReorder={handleReorder}
        indexOfEdit={indexOfEdit}
        spaceBetweenItems={8}
        isDragAndDropListV1
      >
        {children}
      </DragAndDropList>
    </EditableSortableListContext.Provider>
  );
};

EditableSortableList.getReorderedItems = <T,>({
  items,
  fromIndex,
  toIndex,
}: {
  items: T[];
  fromIndex: number;
  toIndex: number;
}) => {
  const clonedItems = _.cloneDeep(items);
  const reorderedClonedItems = List.move({list: clonedItems, fromIndex, toIndex});
  return reorderedClonedItems;
};

EditableSortableList.Item = EditableSortableListItem;

export default EditableSortableList;
