// Libraries
import _ from 'lodash';

export interface StepType {
  kind: string;
  title: string;
  description: string;
}

const EMPTY_STEP: StepType = {
  kind: '',
  title: '',
  description: '',
};

const OFFICE_APP = 'OFFICE_APP';

const STEPS: StepType[] = [
  {
    kind: OFFICE_APP,
    title: 'Office App',
    description: 'Enter the contracted number of trucks.',
  },
];

const getStep = (kind: string): StepType => {
  return _.find(STEPS, (step) => step.kind === kind) || EMPTY_STEP;
};

const getPreviousStep = (kind: string): StepType => {
  const index = _.findIndex(STEPS, (step) => step.kind === kind);
  const step = index <= 0 ? undefined : STEPS[index - 1];
  return step || EMPTY_STEP;
};

const getNextStep = (kind: string): StepType => {
  const index = _.findIndex(STEPS, (step) => step.kind === kind);
  const step = index < 0 || index >= STEPS.length - 1 ? undefined : STEPS[index + 1];
  return step || EMPTY_STEP;
};

const AddCoreAppsToCompanyStep = {
  OFFICE_APP,

  STEPS,
  getStep,
  getPreviousStep,
  getNextStep,

  getPreviousStepKind: (kind: string) => AddCoreAppsToCompanyStep.getPreviousStep(kind).kind,
  getNextStepKind: (kind: string) => AddCoreAppsToCompanyStep.getNextStep(kind).kind,
};

export default AddCoreAppsToCompanyStep;
