// Supermove
import {gql} from '@supermove/graphql';
import {UserFlowRunModel} from '@supermove/models';
import {Environment} from '@supermove/sdk';
import {colors} from '@supermove/styles';
import {withFragment} from '@supermove/utils';

const getLink = withFragment(
  (userFlowRun: UserFlowRunModel) => {
    return `${Environment.getCustomerAppUrl()}/0/${userFlowRun.project.organization.slug}/document-flows/${userFlowRun.uuid}`;
  },
  gql`
    fragment UserFlowRun_getLink on UserFlowRun {
      id
      uuid
      project {
        organization {
          slug
        }
      }
    }
  `,
);

const getStepsProgressDotColor = withFragment(
  (userFlowRun: UserFlowRunModel) => {
    if (userFlowRun.stepsCompletedCount === 0) {
      return colors.red700;
    }
    if (userFlowRun.stepsCompletedCount === userFlowRun.stepCount) {
      return colors.green700;
    }
    return colors.orange700;
  },
  gql`
    fragment UserFlowRun_getStepsProgressDotColor on UserFlowRun {
      id
      stepsCompletedCount
      stepCount
    }
  `,
);

const getStepsProgressText = withFragment(
  (userFlowRun: UserFlowRunModel) => {
    return `${userFlowRun.stepsCompletedCount}/${userFlowRun.stepCount} steps`;
  },
  gql`
    fragment UserFlowRun_getStepsProgressText on UserFlowRun {
      id
      stepsCompletedCount
      stepCount
    }
  `,
);

const UserFlowRun = {
  getLink,
  getStepsProgressDotColor,
  getStepsProgressText,
};

export default UserFlowRun;
