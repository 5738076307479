// Libraries
import React from 'react';

// Supermove
import {PreventPropagation, Styled} from '@supermove/components';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import {ActionType, DefaultItemType} from '@shared/design/components/Table/components/TableBuilder';
import TextTooltip from '@shared/design/components/TextTooltip';

const Container = Styled.View`
`;

const getActionWithHookOutput = <T extends DefaultItemType>({
  action,
  item,
}: {
  action: ActionType<T>;
  item: T;
}): ActionType<T> => {
  const hookOutput = action.actionHook?.hook(action.actionHook?.hookArgument);
  return {
    ...action,
    onPress: () => action.onPress({item, handleOpen: hookOutput?.handleOpen}),
    hookOutput,
  };
};

const ItemActionButton = <T extends DefaultItemType>({
  action,
  item,
}: {
  action: ActionType<T>;
  item: T;
}) => {
  const actionWithHookOutput = getActionWithHookOutput({action, item});
  const {hookOutput} = actionWithHookOutput;
  const onPress = () =>
    action.onPress({
      item,
      handleOpen: hookOutput?.handleOpen,
      handleSubmit: hookOutput?.handleSubmit,
    });

  return (
    <PreventPropagation>
      <TextTooltip isEnabledMobileToast={false} text={action.tooltip}>
        <Container>
          {action.desktopIcon && (
            <IconButton
              source={action.desktopIcon}
              size={action.desktopIconSize}
              onPress={onPress}
              isDisabled={action.isDisabled || hookOutput?.submitting}
            />
          )}
          {action.desktopLabel && (
            <TertiaryButton
              text={action.desktopLabel}
              onPress={onPress}
              isResponsive
              isDisabled={action.isDisabled || hookOutput?.submitting}
            />
          )}
        </Container>
      </TextTooltip>
      {actionWithHookOutput.actionHook?.renderComponent &&
        actionWithHookOutput.actionHook.renderComponent({
          item,
          isOpen: hookOutput?.isOpen ?? false,
          handleClose: hookOutput?.handleClose,
          hookKey: hookOutput?.key,
        })}
    </PreventPropagation>
  );
};

export default ItemActionButton;
