// Supermove
import {gql} from '@supermove/graphql';
import {ProductUserModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';
import SupermoveProductStatus from '@shared/modules/SupermoveProduct/enums/SupermoveProductStatus';

const isSalesActive = withFragment(
  (productUser: ProductUserModel) => {
    return (
      productUser.supermoveProduct.kind === SupermoveProductKind.SALES &&
      productUser.supermoveProduct.status === SupermoveProductStatus.ACTIVE
    );
  },
  gql`
    fragment ProductUser_isSalesActive on ProductUser {
      id
      supermoveProduct {
        id
        kind
        status
      }
    }
  `,
);

const ProductUser = {
  isSalesActive,
};

export default ProductUser;
