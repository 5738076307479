// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// Shared
import UpdatePrimaryPhoneForwardingPhoneNumberForm, {
  UpdatePrimaryPhoneForwardingPhoneNumberFormProps,
} from '@shared/modules/Settings/forms/UpdatePrimaryPhoneForwardingPhoneNumberForm';

const useUpdatePrimaryPhoneForwardingPhoneNumberMutation = ({
  updatePrimaryPhoneForwardingPhoneNumberForm,
  onSuccess,
  onError,
}: {
  updatePrimaryPhoneForwardingPhoneNumberForm: UpdatePrimaryPhoneForwardingPhoneNumberFormProps;
  onSuccess: () => void;
  onError: (errors: any) => void;
}) => {
  const form = useForm({
    initialValues: {
      updatePrimaryPhoneForwardingPhoneNumberForm:
        UpdatePrimaryPhoneForwardingPhoneNumberForm.toForm(
          updatePrimaryPhoneForwardingPhoneNumberForm,
        ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdatePrimaryPhoneForwardingPhoneNumberMutation.mutation,
    variables: {
      updatePrimaryPhoneForwardingPhoneNumberForm:
        UpdatePrimaryPhoneForwardingPhoneNumberForm.toMutation(
          form.values.updatePrimaryPhoneForwardingPhoneNumberForm,
        ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdatePrimaryPhoneForwardingPhoneNumberMutation.mutation = gql`
  mutation useUpdatePrimaryPhoneForwardingPhoneNumberMutation($updatePrimaryPhoneForwardingPhoneNumberForm: UpdatePrimaryPhoneForwardingPhoneNumberForm!) {
    response: updatePrimaryPhoneForwardingPhoneNumber(updatePrimaryPhoneForwardingPhoneNumberForm: $updatePrimaryPhoneForwardingPhoneNumberForm) {
      ${gql.errors}
      settings {
        id
        forwardingPhoneNumber
      }
    }
  }
`;

export default useUpdatePrimaryPhoneForwardingPhoneNumberMutation;
