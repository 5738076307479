// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, IconSource, ScrollView, Space, Styled} from '@supermove/components';
import {ScrollViewType, useEffect, useResponsive, useScrollView, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ViewStyleProp,
} from '@supermove/styles/types';

// Components
import BetaBadge from '@shared/design/components/BetaBadge';

const Container = Styled.View`
`;

const EnclosedTabsItemsContainer = Styled.View`
  flex-direction: row;
  background-color: ${colors.white};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 5px;
  padding: 4px;
`;

const TabUnderline = Styled.View`
  position: absolute;
  bottom: 0px;
  height: 4px;
  width: 100%;
  border-radius: 2px;
  background-color: ${colors.blue.interactive};
`;

const TabSeparator = Styled.View<{isSecondary: boolean}>`
  height: ${({isSecondary}) => (isSecondary ? '20px' : '24px')};
  width: 1px;
  background-color: ${colors.gray.tertiary};
`;

const Text = Styled.Text<{vars: {isActive?: boolean; isSecondary?: boolean}}>`
  ${({vars}) => (vars.isSecondary ? Typography.Label2 : Typography.Label1)}
  color: ${({vars}) => (vars.isActive ? colors.blue.interactive : colors.gray.tertiary)};
`;

const TabButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
`;

const TabCountContainer = Styled.View<{isActive?: boolean}>`
  padding-vertical: 2px;
  padding-horizontal: 8px;
  border-radius: 56px;
  background-color: ${({isActive}) => (isActive ? colors.blue.interactive : colors.gray.tertiary)};
`;

const TabCountText = Styled.Text<{vars: {isSecondary?: boolean}}>`
  ${({vars}) => (vars.isSecondary ? Typography.Label4 : Typography.MicroLabel)}
  color: ${colors.white};
`;

export interface TabType {
  label: string;
  count?: number | React.ReactNode;
  icon?: IconSource;
  colorOverride?: string;
  isBeta?: boolean;
  prependSeparator?: boolean;
}
export interface TabWithIndexType extends TabType {
  index: number;
}
type TabComponentType<T> = React.FC<TabComponentProps<T>>;
export type TabComponentProps<T> = {
  tab: T;
  handlePressTab: () => void;
  index?: number;
  isActive?: boolean;
  isEnclosed?: boolean;
  isSpacedTabs?: boolean;
  setPositions?: React.Dispatch<React.SetStateAction<number[]>>;
  style?: React.CSSProperties;
  children?: React.ReactNode;
};
export type HandlePressTabType<T> = (tab: T & TabType) => void;

const TabText = ({
  isActive = false,
  isSecondary = false,
  style,
  children,
}: {
  isActive?: boolean;
  isSecondary?: boolean;
  style?: React.CSSProperties;
  children: React.ReactNode;
}) => {
  return (
    <Text style={style} vars={{isActive, isSecondary}}>
      {children}
    </Text>
  );
};

const TabCount = ({
  tab,
  isActive = false,
  isSecondary = false,
  style,
}: {
  tab: TabType;
  isActive?: boolean;
  isSecondary?: boolean;
  style?: React.CSSProperties;
}) => {
  return (
    <TabCountContainer isActive={isActive} style={style}>
      <TabCountText vars={{isSecondary}}>{tab.count}</TabCountText>
    </TabCountContainer>
  );
};

const TabContent = <T extends TabType>({
  tab,
  isActive,
  isSecondary,
  isEnclosed,
  children,
}: {
  tab: T;
  isActive?: boolean;
  isSecondary?: boolean;
  isEnclosed?: boolean;
  children?: React.ReactNode;
}) => {
  return (
    <React.Fragment>
      {tab.icon && (
        <React.Fragment>
          <Icon
            source={tab.icon}
            size={16}
            color={
              tab.colorOverride
                ? tab.colorOverride
                : isActive
                  ? colors.blue.interactive
                  : colors.gray.tertiary
            }
          />
          <Space width={8} />
        </React.Fragment>
      )}
      <TabText
        isActive={isActive}
        isSecondary={isSecondary}
        style={tab.colorOverride ? {color: tab.colorOverride} : {}}
      >
        {tab.label}
      </TabText>
      {!_.isNil(tab.count) && (
        <React.Fragment>
          <Space width={8} />
          <TabCount
            tab={tab}
            isActive={isActive}
            isSecondary={isSecondary}
            style={tab.colorOverride ? {backgroundColor: tab.colorOverride} : {}}
          />
        </React.Fragment>
      )}
      {children || null}
      {tab.isBeta && (
        <React.Fragment>
          <Space width={8} />
          <BetaBadge isSmall />
        </React.Fragment>
      )}
      {isActive && !isEnclosed && (
        <TabUnderline style={tab.colorOverride ? {backgroundColor: tab.colorOverride} : {}} />
      )}
    </React.Fragment>
  );
};

const Tab = ({
  tab,
  handlePressTab,
  index = 0,
  isActive = false,
  isSecondary = false,
  isEnclosed = false,
  isSpacedTabs = true,
  setPositions,
  style,
  children,
}: {
  tab: TabWithIndexType;
  handlePressTab: () => void;
  index?: number;
  isActive?: boolean;
  isSecondary?: boolean;
  isEnclosed?: boolean;
  isSpacedTabs?: boolean;
  setPositions?: React.Dispatch<React.SetStateAction<number[]>>;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}) => {
  const isEnclosedStyle = isEnclosed
    ? {
        backgroundColor: isActive ? colors.blue.accent : colors.white,
        borderRadius: 5,
        minWidth: 100,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 8,
        paddingRight: 8,
        flex: 1,
      }
    : {};
  const tabButtonStyle = {
    ...isEnclosedStyle,
    ...style,
  };
  return (
    <React.Fragment>
      {tab.prependSeparator && (
        <React.Fragment>
          <Space width={12} />
          <TabSeparator isSecondary={isSecondary} />
          <Space width={12} />
        </React.Fragment>
      )}
      {isSpacedTabs && !!tab.index && !isEnclosed && <Space width={12} />}
      <TabButton
        onPress={handlePressTab}
        isActive={isActive}
        style={tabButtonStyle}
        onLayout={({nativeEvent}: LayoutChangeEvent) => {
          setPositions?.((positions: number[]) => {
            const updatedPositions = [...positions];
            updatedPositions[index] = nativeEvent.layout.x;
            return updatedPositions;
          });
        }}
      >
        {children}
      </TabButton>
      {isSpacedTabs && !isEnclosed && <Space width={12} />}
    </React.Fragment>
  );
};

const PrimaryTab = <T,>({
  tab,
  handlePressTab,
  isActive,
  style,
  isEnclosed,
  isSpacedTabs,
  index,
  setPositions,
  children,
}: TabComponentProps<T & TabWithIndexType>) => {
  return (
    <Tab
      isEnclosed={isEnclosed}
      isSpacedTabs={isSpacedTabs}
      tab={tab}
      handlePressTab={handlePressTab}
      isActive={isActive}
      style={style}
      index={index}
      setPositions={setPositions}
    >
      <TabContent tab={tab} isActive={isActive} isEnclosed={isEnclosed}>
        {children}
      </TabContent>
    </Tab>
  );
};

const SecondaryTab = <T,>({
  tab,
  handlePressTab,
  isActive,
  style,
  isEnclosed,
  isSpacedTabs,
  index,
  setPositions,
}: TabComponentProps<T & TabWithIndexType>) => {
  return (
    <Tab
      isEnclosed={isEnclosed}
      isSpacedTabs={isSpacedTabs}
      tab={tab}
      handlePressTab={handlePressTab}
      isActive={isActive}
      isSecondary
      style={style}
      index={index}
      setPositions={setPositions}
    >
      <TabContent tab={tab} isActive={isActive} isSecondary />
    </Tab>
  );
};

const TabsItemsWrapper = ({
  isEnclosed,
  isFullWidth,
  scrollViewStyle,
  setCurrentPosition,
  scrollView,
  setLayoutWidth,
  children,
}: {
  isEnclosed?: boolean;
  isFullWidth?: boolean;
  scrollViewStyle?: ViewStyleProp;
  setCurrentPosition: (position: number) => void;
  scrollView: ScrollViewType;
  setLayoutWidth: (width: number) => void;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();

  if (isEnclosed) {
    return <EnclosedTabsItemsContainer>{children}</EnclosedTabsItemsContainer>;
  }
  return (
    <ScrollView
      ref={scrollView.ref}
      horizontal
      style={scrollViewStyle}
      contentContainerStyle={isFullWidth ? {flex: 1} : null}
      showsHorizontalScrollIndicator={responsive.desktop}
      onScroll={({nativeEvent}: NativeSyntheticEvent<NativeScrollEvent>) => {
        setCurrentPosition(nativeEvent.contentOffset.x);
      }}
      onLayout={({nativeEvent}: {nativeEvent: {layout: {width: number}}}) => {
        setLayoutWidth(nativeEvent.layout.width);
      }}
    >
      {children}
    </ScrollView>
  );
};

const TabsItems = <T,>({
  tabs,
  TabComponent,
  handlePressTab,
  activeTabIndex,
  isEnclosed,
  isFullWidth,
  isSpacedTabs,
  scrollViewStyle,
  tabStyle,
}: {
  tabs: (T & TabType)[];
  TabComponent: TabComponentType<T & TabWithIndexType>;
  handlePressTab: HandlePressTabType<T & TabWithIndexType>;
  activeTabIndex: number;
  isEnclosed?: boolean;
  isFullWidth?: boolean;
  isSpacedTabs?: boolean;
  scrollViewStyle?: ViewStyleProp;
  tabStyle?: React.CSSProperties;
}) => {
  const [layoutWidth, setLayoutWidth] = useState(0);
  const [positions, setPositions] = useState<number[]>([]);
  const [currentPosition, setCurrentPosition] = useState(0);
  const scrollView = useScrollView();

  // Scroll so that the active tab is fully in view
  useEffect(() => {
    const loadedPositionsCount = positions.filter((position) => position !== undefined).length;
    const isAllLoaded = loadedPositionsCount === tabs.length;
    if (!isAllLoaded || !scrollView.ref.current) {
      return;
    }

    const endPosition = currentPosition + layoutWidth;

    if (activeTabIndex === 0) {
      scrollView.handleScrollToTop({animated: true});
    } else if (activeTabIndex === tabs.length - 1) {
      scrollView.handleScrollToEnd({animated: true});
    } else {
      const activeTabStartPosition = positions[activeTabIndex];
      const activeTabEndPosition = positions[activeTabIndex + 1];
      const isStartInView =
        activeTabStartPosition >= currentPosition && activeTabStartPosition <= endPosition;
      const isEndInView =
        activeTabEndPosition >= currentPosition && activeTabEndPosition <= endPosition;
      if (!isStartInView) {
        scrollView.handleScrollTo({x: activeTabStartPosition, animated: true});
      } else if (!isEndInView) {
        scrollView.handleScrollTo({x: activeTabEndPosition - layoutWidth, animated: true});
      }
    }
  }, [positions, activeTabIndex]);

  return (
    <TabsItemsWrapper
      isEnclosed={isEnclosed}
      isFullWidth={isFullWidth}
      scrollViewStyle={scrollViewStyle}
      setCurrentPosition={setCurrentPosition}
      scrollView={scrollView}
      setLayoutWidth={setLayoutWidth}
    >
      {tabs.map((tab, index) => {
        const tabWithIndex = {...tab, index};
        return (
          <TabComponent
            key={index}
            tab={tabWithIndex}
            handlePressTab={() => handlePressTab(tabWithIndex)}
            index={index}
            isActive={index === activeTabIndex}
            isEnclosed={isEnclosed}
            isSpacedTabs={isSpacedTabs}
            setPositions={setPositions}
            style={tabStyle}
          />
        );
      })}
    </TabsItemsWrapper>
  );
};

export interface TabsProps<T> {
  tabs: (T & TabType)[];
  handlePressTab: HandlePressTabType<T & TabWithIndexType>;
  TabComponent?: TabComponentType<T & TabWithIndexType>;
  activeTabIndex?: number;
  style?: React.CSSProperties;
  scrollViewStyle?: ViewStyleProp;
  tabStyle?: React.CSSProperties;
  isEnclosed?: boolean;
  isFullWidth?: boolean;
  isSpacedTabs?: boolean;
}

const Tabs = <T,>({
  tabs,
  handlePressTab,
  TabComponent = PrimaryTab,
  activeTabIndex = 0,
  style,
  scrollViewStyle = {flex: 1},
  tabStyle,
  isEnclosed = false,
  isFullWidth = false,
  isSpacedTabs = true,
}: TabsProps<T>) => {
  return (
    <Container style={style}>
      <TabsItems
        tabs={tabs}
        TabComponent={TabComponent}
        activeTabIndex={activeTabIndex}
        handlePressTab={handlePressTab}
        isEnclosed={isEnclosed}
        isFullWidth={isFullWidth}
        isSpacedTabs={isSpacedTabs}
        scrollViewStyle={scrollViewStyle}
        tabStyle={tabStyle}
      />
    </Container>
  );
};

Tabs.PrimaryTab = PrimaryTab;
Tabs.SecondaryTab = SecondaryTab; // Pass this in as TabComponent for smaller tabs
Tabs.TabsItems = TabsItems;
Tabs.Tab = Tab;
Tabs.TabContent = TabContent;
Tabs.TabButton = TabButton;
Tabs.TabText = TabText;
Tabs.TabCount = TabCount;
Tabs.TabUnderline = TabUnderline;
Tabs.TabSeparator = TabSeparator;

export default Tabs;
