// Supermove
import {gql} from '@supermove/graphql';
import {CompanyModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import {CompanyKindType} from '@shared/modules/Company/enums/CompanyKind';

export interface CompanyDetailsProfileFormType {
  companyId: string;
  kind: CompanyKindType;
  name: string;
}

const edit = withFragment(
  (company: CompanyModel) => ({
    companyId: company.id,
    kind: company.kind,
    name: company.name,
  }),
  gql`
    fragment CompanyDetailsProfileForm_edit on Company {
      id
      kind
      name
    }
  `,
);

export type CompanyDetailsProfileFormToFormType = CompanyDetailsProfileFormType;

const toForm = ({
  companyId,
  kind,
  name,
}: CompanyDetailsProfileFormType): CompanyDetailsProfileFormToFormType => ({
  companyId,
  kind,
  name,
});

const toMutation = ({companyId, kind, name}: CompanyDetailsProfileFormToFormType) => ({
  companyId,
  kind,
  name,
});

const CompanyDetailsProfileForm = {
  edit,
  toForm,
  toMutation,
};

export default CompanyDetailsProfileForm;
