const ACTIVE = 'ACTIVE' as const;
const INACTIVE = 'INACTIVE' as const;
const PENDING = 'PENDING' as const;

const STATUSES = [ACTIVE, INACTIVE, PENDING] as const;

export type UserStatusType = (typeof STATUSES)[number];

const UserStatus = {
  ACTIVE,
  INACTIVE,
  PENDING,
};

export default UserStatus;
