const INBOUND = 'INBOUND' as const;
const OUTBOUND = 'OUTBOUND' as const;

const getCallDirectionDropdownOptions = () => [
  {label: 'Inbound', value: INBOUND},
  {label: 'Outbound', value: OUTBOUND},
];

const CallDirection = {
  INBOUND,
  OUTBOUND,
  getCallDirectionDropdownOptions,
};

export type CallDirectionType = typeof INBOUND | typeof OUTBOUND;

export default CallDirection;
