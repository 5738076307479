// Supermove
import {Icon, IconSource} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ConversationModel, EmailStatus as EmailStatusModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {withFragment} from '@supermove/utils';

// App
import CallStatus, {CallStatusType} from '@shared/modules/Call/enums/CallStatus';
import EmailStatus from '@shared/modules/Email/enums/EmailStatus';

const ConversationStatus = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
} as const;

const RecentCommunicationDirection = {
  INBOUND: 'INBOUND',
  OUTBOUND: 'OUTBOUND',
} as const;

type ConversationStatusType = {
  icon: IconSource;
  backgroundColor: string;
  color: string;
  text: string;
};

const inboundStatus: Omit<ConversationStatusType, 'icon'> = {
  color: colors.green.status,
  backgroundColor: colors.green.accent,
  text: 'Received',
};

const outboundStatus: Omit<ConversationStatusType, 'icon'> = {
  color: colors.blue.interactive,
  backgroundColor: colors.blue.accent,
  text: 'Sent',
};

type BaseStatusParams = {
  direction: 'OUTBOUND' | 'INBOUND';
};
type EmailStatusParams = BaseStatusParams & {type: 'EMAIL'; emailStatus: EmailStatusModel};
type CallStatusParams = BaseStatusParams & {type: 'CALL'; callStatus: CallStatusType};
type TextStatusParams = BaseStatusParams & {type: 'TEXT'};
type UnknownStatusParams = BaseStatusParams & {type: undefined};
type GetStatusParams =
  | EmailStatusParams
  | CallStatusParams
  | TextStatusParams
  | UnknownStatusParams;

export const getStatus = (params: GetStatusParams): ConversationStatusType => {
  const isOutbound = params.direction === 'OUTBOUND';
  if (params.type === 'EMAIL') {
    if (params.emailStatus === EmailStatus.ERROR) {
      return {
        icon: Icon.Envelope,
        color: colors.orange.status,
        backgroundColor: colors.orange.accent,
        text: 'Failed',
      };
    }
    if ([EmailStatus.PENDING, EmailStatus.QUEUED].includes(params.emailStatus)) {
      return {
        icon: Icon.Envelope,
        color: colors.gray.tertiary,
        backgroundColor: colors.gray.background,
        text: 'Sending',
      };
    }
    if (isOutbound)
      return {
        icon: Icon.Envelope,
        ...outboundStatus,
      };
    return {
      icon: Icon.Envelope,
      ...inboundStatus,
    };
  }
  if (params.type === 'TEXT') {
    if (isOutbound) {
      return {
        icon: Icon.CommentSms,
        ...outboundStatus,
      };
    }
    return {
      icon: Icon.CommentSms,
      ...inboundStatus,
    };
  }
  if (params.type === 'CALL') {
    if ([CallStatus.ERROR, CallStatus.CANCELLED].includes(params.callStatus)) {
      return {
        icon: Icon.PhoneMissed,
        color: colors.red.warning,
        backgroundColor: colors.red.accent,
        text: 'Failed',
      };
    }
    if (params.callStatus === CallStatus.MISSED) {
      return {
        icon: Icon.PhoneSlash,
        color: colors.orange.status,
        backgroundColor: colors.orange.accent,
        text: 'Missed',
      };
    }
    if (isOutbound) {
      return {
        ...outboundStatus,
        icon: Icon.PhoneOutgoing,
        text: 'Outbound',
      };
    }
    return {
      ...inboundStatus,
      icon: Icon.PhoneIncoming,
      text: 'Inbound',
    };
  }
  return {
    icon: Icon.Question,
    color: colors.gray.background,
    backgroundColor: colors.gray.border,
    text: 'Unknown',
  };
};

const getStatusInfo = withFragment(
  (conversation: ConversationModel): ConversationStatusType => {
    const type = (() => {
      if (conversation.email) {
        return 'EMAIL';
      }
      if (conversation.textMessage) {
        return 'TEXT';
      }
      if (conversation.call) {
        return 'CALL';
      }
    })();
    return getStatus({
      direction: conversation.recentCommunicationDirection,
      type,
      emailStatus: conversation.email?.status as EmailStatusModel,
      callStatus: conversation.call?.status as CallStatusType,
    });
  },
  gql`
    fragment Conversation_getStatusInfo on Conversation {
      id
      recentCommunicationDirection
      email {
        id
        status
      }
      textMessage {
        id
      }
      call {
        id
        direction
        status
      }
    }
  `,
);

const Conversation = {
  ConversationStatus,
  getStatusInfo,
  RecentCommunicationDirection,
};

export default Conversation;
