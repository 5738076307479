// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SalesAppSettingsForm from '@shared/modules/Settings/forms/SalesAppSettingsForm';

const useToggleSalesAppSettingsDialExternalProviderForOutboundCallsMutation = ({
  salesAppSettingsForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      salesAppSettingsForm: SalesAppSettingsForm.toForm(salesAppSettingsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useToggleSalesAppSettingsDialExternalProviderForOutboundCallsMutation.mutation,
    variables: {
      salesAppSettingsForm: SalesAppSettingsForm.toMutation(form.values.salesAppSettingsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useToggleSalesAppSettingsDialExternalProviderForOutboundCallsMutation.mutation = gql`
  mutation useToggleSalesAppSettingsDialExternalProviderForOutboundCallsMutation($salesAppSettingsForm: SalesAppSettingsForm!) {
    response: toggleSalesAppSettingsDialExternalProviderForOutboundCalls(salesAppSettingsForm: $salesAppSettingsForm) {
      ${gql.errors}
      salesAppSettings {
        id
        dialExternalProviderForOutboundCalls
      }
    }
  }
`;

export default useToggleSalesAppSettingsDialExternalProviderForOutboundCallsMutation;
