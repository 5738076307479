// Supermove
import {gql} from '@supermove/graphql';
import {LeadStageModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

const getActiveLeadStages = withFragment(
  (leadStages: LeadStageModel[]) => {
    return leadStages.filter((stage) => !stage.archivedAt);
  },
  gql`
    fragment LeadStage_getActiveLeadStages on LeadStage {
      id
      archivedAt
    }
  `,
);

const getArchivedLeadStages = withFragment(
  (leadStages: LeadStageModel[]) => {
    return leadStages.filter((stage) => !!stage.archivedAt);
  },
  gql`
    fragment LeadStage_getArchivedLeadStages on LeadStage {
      id
      archivedAt
    }
  `,
);

const LeadStage = {
  getActiveLeadStages,
  getArchivedLeadStages,
};

export default LeadStage;
