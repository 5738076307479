// Supermove
import {gql} from '@supermove/graphql';
import {EmailModel} from '@supermove/models';
import {withFragment, Datetime} from '@supermove/utils';

const getDisplayDate = withFragment(
  (email: EmailModel) => {
    return email.sentAt
      ? Datetime.toDisplayDate(Datetime.fromDatetime(email.sentAt), 'M/DD/YYYY')
      : null;
  },
  gql`
    fragment Email_getDisplayDate on Email {
      id
      sentAt
    }
  `,
);

const getDisplayTime = withFragment(
  (email: EmailModel) => {
    return email.sentAt ? Datetime.toDisplayTime(Datetime.fromDatetime(email.sentAt)) : null;
  },
  gql`
    fragment Email_getDisplayTime on Email {
      id
      sentAt
    }
  `,
);

const Email = {
  getDisplayDate,
  getDisplayTime,
};

export default Email;
