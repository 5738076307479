// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import Callout, {CalloutProps} from '@shared/design/components/Callout';

const ErrorCallout = (props: CalloutProps) => {
  return (
    <Callout
      textColor={colors.red.warning}
      backgroundColor={colors.red.accent}
      borderColor={colors.red.warning}
      icon={Icon.Ban}
      iconColor={colors.red.warning}
      {...props}
    />
  );
};

ErrorCallout.Text = Callout.Text;
ErrorCallout.LinkText = Callout.LinkText;
ErrorCallout.Link = Callout.Link;

export default ErrorCallout;
