// Supermove
import {gql} from '@supermove/graphql';
import {Currency, Float, Percent, pluralize, withFragment} from '@supermove/utils';

import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemTypeKind from '@shared/modules/Billing/enums/BillItemTypeKind';
import BillItemUnit from '@shared/modules/Billing/enums/BillItemUnit';
import BillStage from '@shared/modules/Billing/enums/BillStage';

const FEE_HOUR = {
  category: BillItemTypeCategory.FEES,
  kind: BillItemTypeKind.AMOUNT,
  billStage: BillStage.PRE_SUBTOTAL,
  unit: BillItemUnit.HOUR,
  name: 'Hourly Rate',
  displayName: 'Hourly Rate',
};
const FEE_AMOUNT_PRE_SUBTOTAL = {
  category: BillItemTypeCategory.FEES,
  kind: BillItemTypeKind.AMOUNT,
  billStage: BillStage.PRE_SUBTOTAL,
  unit: null,
  name: 'Flat Rate',
  displayName: 'Flat Rate',
};
const SUPPLY = {
  category: BillItemTypeCategory.SUPPLIES,
  kind: BillItemTypeKind.AMOUNT,
  billStage: BillStage.PRE_SUBTOTAL,
  unit: null,
  name: 'Supplies',
  displayName: 'Supplies',
};
const DISCOUNT_AMOUNT_PRE_SUBTOTAL = {
  category: BillItemTypeCategory.DISCOUNTS,
  kind: BillItemTypeKind.AMOUNT,
  billStage: BillStage.PRE_SUBTOTAL,
  unit: null,
  name: 'Discount',
  displayName: 'Discount',
};
const FEE_AMOUNT_POST_SUBTOTAL = {
  category: BillItemTypeCategory.FEES,
  kind: BillItemTypeKind.AMOUNT,
  billStage: BillStage.POST_SUBTOTAL,
  unit: null,
  name: 'Flat Fee',
  displayName: 'Flat Fee (After Subtotal)',
};
const FEE_PERCENT = {
  category: BillItemTypeCategory.FEES,
  kind: BillItemTypeKind.PERCENTAGE,
  billStage: BillStage.POST_SUBTOTAL,
  unit: null,
  name: 'Percent Fee',
  displayName: 'Percent Fee (After Subtotal)',
};
const DISCOUNT_AMOUNT_POST_SUBTOTAL = {
  category: BillItemTypeCategory.DISCOUNTS,
  kind: BillItemTypeKind.AMOUNT,
  billStage: BillStage.POST_SUBTOTAL,
  unit: null,
  name: 'Flat Discount',
  displayName: 'Flat Discount (After Subtotal)',
};
const DISCOUNT_PERCENT = {
  category: BillItemTypeCategory.DISCOUNTS,
  kind: BillItemTypeKind.PERCENTAGE,
  billStage: BillStage.POST_SUBTOTAL,
  unit: null,
  name: 'Percent Discount',
  displayName: 'Percent Discount (After Subtotal)',
};
const CUSTOM_BILL_ITEM_VALUES = {
  FEE_HOUR,
  FEE_AMOUNT_PRE_SUBTOTAL,
  SUPPLY,
  DISCOUNT_AMOUNT_PRE_SUBTOTAL,
  FEE_AMOUNT_POST_SUBTOTAL,
  FEE_PERCENT,
  DISCOUNT_AMOUNT_POST_SUBTOTAL,
  DISCOUNT_PERCENT,
};
const CUSTOM_BILL_ITEMS = [
  FEE_HOUR,
  FEE_AMOUNT_PRE_SUBTOTAL,
  SUPPLY,
  DISCOUNT_AMOUNT_PRE_SUBTOTAL,
  FEE_AMOUNT_POST_SUBTOTAL,
  FEE_PERCENT,
  DISCOUNT_AMOUNT_POST_SUBTOTAL,
  DISCOUNT_PERCENT,
];
const FILTERS = [
  FEE_HOUR,
  FEE_AMOUNT_PRE_SUBTOTAL,
  SUPPLY,
  DISCOUNT_AMOUNT_PRE_SUBTOTAL,
  FEE_AMOUNT_POST_SUBTOTAL,
  FEE_PERCENT,
  DISCOUNT_AMOUNT_POST_SUBTOTAL,
  DISCOUNT_PERCENT,
];

const getDisplayPrice = withFragment(
  (billItem, {isEnabledTbdBillItems}) => {
    if ((billItem as any).kind === BillItemTypeKind.PERCENTAGE) {
      if (isEnabledTbdBillItems && (billItem as any).percentage === null) {
        return 'TBD';
      }
      return Percent.display((billItem as any).percentage);
    }
    if (isEnabledTbdBillItems && (billItem as any).amount === null) {
      return 'TBD';
    }
    const formattedAmount = Currency.display((billItem as any).amount);
    if ((billItem as any).unit === BillItemUnit.HOUR) {
      return `${formattedAmount} / hour`;
    }
    return formattedAmount;
  },
  gql`
    fragment BillItem_getDisplayPrice on BillItem {
      id
      kind
      unit
      percentage
      amount
    }
  `,
);

const getQuantityWithUnits = ({
  text,
  billItem,
  hasDifferentQuantityTwo,
}: {
  text: string;
  billItem: any;
  hasDifferentQuantityTwo: boolean;
}) => {
  switch ((billItem as any).unit) {
    case BillItemUnit.HOUR:
      if (hasDifferentQuantityTwo) {
        return `${text} ${pluralize((billItem as any).unit, (billItem as any).maxQuantity)}`;
      }
      return `${text} ${pluralize((billItem as any).unit, (billItem as any).minQuantity)}`;
    default:
      return text;
  }
};

const getEstimateQuantity = withFragment(
  (billItem, {isEnabledTbdBillItems}) => {
    let hasDifferentQuantityTwo = false;
    if (isEnabledTbdBillItems) {
      // HACK(jholston): getEstimateQuantity is used by BillItemType, which defaults TBD to "" instead of null.
      const isTbd = (quantity: any) => quantity === null || quantity === '';
      if (isTbd((billItem as any).minQuantity) && isTbd((billItem as any).maxQuantity)) {
        return 'TBD';
      }
      // If there is only a maxQuantity and minQuantity is TBD, we display a single quantity
      if (isTbd((billItem as any).minQuantity) && !isTbd((billItem as any).maxQuantity)) {
        return getQuantityWithUnits({
          text: `${(billItem as any).maxQuantity}`,
          billItem,
          hasDifferentQuantityTwo: true,
        });
      }
      hasDifferentQuantityTwo =
        !isTbd((billItem as any).maxQuantity) &&
        Float.toFloat((billItem as any).maxQuantity) > Float.toFloat((billItem as any).minQuantity);
    } else {
      if (!(billItem as any).minQuantity && !(billItem as any).maxQuantity) {
        return 'TBD';
      }
      hasDifferentQuantityTwo =
        !!(billItem as any).maxQuantity &&
        Float.toFloat((billItem as any).maxQuantity) > Float.toFloat((billItem as any).minQuantity);
    }

    const text = hasDifferentQuantityTwo
      ? `${(billItem as any).minQuantity} - ${(billItem as any).maxQuantity}`
      : (billItem as any).minQuantity;
    return getQuantityWithUnits({text, billItem, hasDifferentQuantityTwo});
  },
  gql`
    fragment BillItem_getEstimateQuantity on BillItem {
      id
      kind
      minQuantity
      maxQuantity
      unit
    }
  `,
);

const getEstimateTotal = withFragment(
  (billItem, {isEnabledTbdBillItems}) => {
    const isTbd = isEnabledTbdBillItems
      ? (billItem as any).totalMin === null && (billItem as any).totalMax === null
      : !(billItem as any).totalMin && !(billItem as any).totalMax;
    if (isTbd) {
      return 'TBD';
    }

    // @ts-expect-error TS(2345): Argument of type '{ min: any; max: any; }' is not ... Remove this comment to see the full error message
    return Currency.formatRange({
      min: (billItem as any).totalMin,
      max: (billItem as any).totalMax,
    });
  },
  gql`
    fragment BillItem_getEstimateTotal on BillItem {
      id
      totalMin
      totalMax
    }
  `,
);

const getEstimateTotalPostSubtotal = withFragment(
  (billItem, {isSubtotalAvailable, isEnabledTbdBillItems}) => {
    if (isSubtotalAvailable || (billItem as any).kind === BillItemTypeKind.AMOUNT) {
      if (
        isEnabledTbdBillItems &&
        ((billItem as any).totalMin === null || (billItem as any).totalMax === null)
      ) {
        return 'TBD';
      }
      // @ts-expect-error TS(2345): Argument of type '{ min: any; max: any; }' is not ... Remove this comment to see the full error message
      return Currency.formatRange({
        min: (billItem as any).totalMin,
        max: (billItem as any).totalMax,
      });
    }
    return 'TBD';
  },
  gql`
    fragment BillItem_getEstimateTotalPostSubtotal on BillItem {
      id
      kind
      totalMin
      totalMax
    }
  `,
);

const BillItem = {
  LINE_ITEM: 'LINE_ITEM',
  BILL_MODIFIER: 'BILL_MODIFIER',

  getDisplayPrice,
  getEstimateQuantity,
  getEstimateTotal,
  getEstimateTotalPostSubtotal,

  CUSTOM_BILL_ITEM_VALUES,
  CUSTOM_BILL_ITEMS,
  FILTERS,
};

export default BillItem;
