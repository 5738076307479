// Libraries
import React from 'react';

// Supermove
import {Icon, IconSource, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const CalloutContainer = Styled.View`
  width: ${({width}: any) => (width ? `${width}px` : '100%')};
  background-color: ${({backgroundColor}: any) => backgroundColor};
  border: 1px solid ${({borderColor}: any) => borderColor};
  border-radius: 4px;
  padding-horizontal: 12px;
  padding-vertical: 8px;
  flex-direction: row;
`;

const CalloutText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${({color}: any) => color};
`;

const CalloutLinkText = Styled.Text`
  ${Typography.Responsive.Link}
  color: ${({color}: any) => color};
  text-decoration-color: ${({color}: any) => color};
`;

const Button = Styled.ButtonV2`
`;

const CalloutLink = ({color, text, onPress}: any) => {
  const responsive = useResponsive();

  return (
    <Button onPress={onPress}>
      <CalloutLinkText responsive={responsive} color={color}>
        {text}
      </CalloutLinkText>
    </Button>
  );
};

export interface CalloutProps {
  text?: React.ReactNode;
  textColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  width?: number | null;
  icon?: IconSource;
  iconColor?: string;
  children?: React.ReactNode;
}

const Callout = ({
  text = '',
  textColor = colors.gray.primary,
  backgroundColor = colors.gray.border,
  borderColor = colors.gray.secondary,
  width = null,
  icon = Icon.InfoCircle,
  iconColor = colors.gray.secondary,
  children,
}: CalloutProps) => {
  const responsive = useResponsive();

  return (
    <CalloutContainer width={width} backgroundColor={backgroundColor} borderColor={borderColor}>
      <Icon
        source={icon}
        size={responsive.desktop ? 16 : 18}
        color={iconColor}
        style={{marginTop: 2}}
      />
      <Space width={8} />
      {text ? (
        <CalloutText responsive={responsive} color={textColor}>
          {text}
        </CalloutText>
      ) : (
        children
      )}
    </CalloutContainer>
  );
};

Callout.Text = CalloutText;
Callout.LinkText = CalloutLinkText;
Callout.Link = CalloutLink;

export default Callout;
